
/**
 * Retrieves the API endpoint for getting users data.
 * @returns {string} The API endpoint URL.
 */

const baseUrl = "https://sso.r-its.fr";

function GetNotifySound(number) {
    const route = `${baseUrl}/lib/rits-package/sounds/NotifySound${number}.mp3`;
    return route;
}

export default {
    GetNotifySound,
};
