/**
 * REACT
 */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
/**
 * Context
 */
import { useToastContext } from "../../context/toast-context.js";
import { initTranslations } from "../../i18n/i18n.js";
import NoSleep from 'nosleep.js';
/**
 * Logger
 */
const RitsReactConsole = require("rits-node-framework/debug/rits-react-console.js");
const _logger = new RitsReactConsole(global.LOG_LEVEL);
/**
 * Services
 */
import {
	LoadDataCompany,
} from "../../data/services/load-data-service.js";
/**
 * Components
 */
import CustomSpinner from "../common/shared/custom-spinner.js";
import HeadMenu from "../common/headers/head-menu.js";
import CustomFooter from "../common/shared/custom-footer.js";
import OrdersContent from "../common/specific/orders-page-content.js";
import ProductsPageContent from "../common/specific/products-page-content.js";
import { setTheme, getTheme } from "../models/themes.js";
/**
 * Constants
 */
const _orderModel = require("../models/orders.js");

const DynamicComponentProductsPageContent = () => {
	return (
		<ProductsPageContent
			properties={{
				pageName: "product-page",
				trNamespace: "products"
			}}
		></ProductsPageContent>
	);
};

const DynamicComponentOrderItems = () => {
	return (
		<OrdersContent
			properties={{
				pageName: "orders-page",
				trNamespace: "orders",

			}}
		></OrdersContent>
	);
};

/**
 * Dashboard
 * @param {*} _props
 * @returns
 */
const Dashboard = (_props) => {
	var props = _props.properties;
	const ticks = new Date().getTime();
	const componentId = "page-dashboard-" + ticks;
	const [loaded, setLoaded] = useState(false);
	const [userEmail, setUserEmail] = useState(null);
	const { t } = initTranslations();
	const { handleShowToast } = useToastContext();
	const _companyModel = require("../models/company.js");
	const navigate = useNavigate();
	const [showProductsPageContent, setShowProductsPageContent] = useState(false);
	const [showOrderItems, setShowOrderItems] = useState(true);
	var noSleep = new NoSleep();

	// Specific
	setTheme(getTheme());
	/**
	 * Use effect
	 */
	useEffect(() => {
		props = _props.properties;
		// Reload data on loaded state change.
		document.addEventListener('click', function enableNoSleep() {
			document.removeEventListener('click', enableNoSleep, false);
			noSleep.enable();
		}, false);
		componentDidMount();
	}, [loaded]);
	/**
	 * Component did mount
	 */
	async function componentDidMount() {
		try {
			await loadAll();
		} catch (e) {
			onError(e);
		}
	}
	/**
	 * Load all datas
	 */
	async function loadAll() {
		_orderModel.emptyOrders();
		var data = global.COMPANY_DATAS;
		if (data == null) {
			data = await LoadDataCompany();
			global.COMPANY_DATAS = data;
		}
		if (data != null && data.companyName != null) {
			setTheme(data.company.CompanyParams);
			//console.log(data);
			props.pageName = data.companyName;
			props.base64Image = data.companyDetails.CompanyLogo;
			setUserEmail(data.name);
			_logger.DEBUG(
				`Loaded ${props.pageName} with id ${componentId} and company ${data.companyName} logged user ${userEmail}`
			);
			document.title = props.pageName;
			_companyModel.setCompanyDatas({
				companyId: data.company.CompanyId,
				companyName: data.companyName,
				companyLogo: data.companyDetails.CompanyLogo,
				currency: data.company.CompanyParams.Currency,
				companyParams: data.company.CompanyParams
			});

			// wait for seconds
			setLoaded(true);
		} else {
			setTimeout(async () => {
				await loadAll();
			}, 500);
		}
	}
	/**
	 *  On error
	 */
	function onError(error) {
		//console.log(error);
		if (process.env.REACT_APP_ENV == "development") {
			handleShowToast(
				"error",
				t("error", { ns: "common" }),
				`Dashboard ${error}`
			);
		}
		navigate("/");
	}


	function setContent() {
		var item1Active = "";
		var item2Active = "";
		if (!showProductsPageContent) {
			item1Active = "active";
		} else {
			item2Active = "active";
		}
		return (
			<>
				<ul className="nav nav-fill nav-tabs nav-pills mt-0 mb-0 p-0 shadow" role="tablist">
					<li className={`nav-item `} role="presentation">
						<a className={`nav-item nav-link ${item1Active}`} id="fill-tab-0" data-bs-toggle="tab" href="#fill-tabpanel-0" role="tab" aria-controls="fill-tabpanel-0" aria-selected="true"
							onClick={() => {
								setShowProductsPageContent(false);
								setShowOrderItems(true);
							}}
						>
							<span className=" h1-mob">{t("orders:orders.title")}</span>
						</a>
					</li>
					<li className={`nav-item`} role="presentation">
						<a className={`nav-item nav-link ${item2Active}`} id="fill-tab-1" data-bs-toggle="tab" href="#fill-tabpanel-1" role="tab"
							onClick={() => {
								console.log("Products")
								setShowOrderItems(false);
								setShowProductsPageContent(true);
							}}
							aria-controls="fill-tabpanel-1" aria-selected="false"> <span className=" h1-mob">{t("products:products")}</span> </a>
					</li>

				</ul>
				<div className="tab-content mt-2" id="tab-content">
					<div className={`tab-pane p-0 mt-0 mb-0 ${item1Active}`} id="fill-tabpanel-0" role="tabpanel" aria-labelledby="fill-tab-0">
						<div
							className="row m-0"
							key={componentId}
							style={{ scrollX: "hidden" }}

						>
							<div className="app-side-margin p-0">
								{showOrderItems ? (
									<DynamicComponentOrderItems
									/>
								) : null}

							</div>
							<div className="app-separator-large"></div >
						</div>
					</div>
					<div className={`tab-pane p-0 mt-0 mb-0 ${item2Active}`} id="fill-tabpanel-1" role="tabpanel" aria-labelledby="fill-tab-1">
						<div
							className="row m-0"
							key={componentId}
							style={{ scrollX: "hidden" }}

						>
							<div className="app-side-margin  p-0 m-0">
								{showProductsPageContent ? (
									<DynamicComponentProductsPageContent
									/>
								) : null}
							</div>
							<div className="app-separator-large"></div >

						</div>
					</div>

				</div></>);
	}

	/**
	 * Set the component
	 */
	return !loaded ? (
		<div className="App bg-primary h-100">
			<header className="App-header">
				<CustomSpinner
					properties={{
						pageName: props.pageName,
						trNamespace: props.trNamespace
					}}
				/>
			</header>
		</div>
	) : (
		<div className="App " key={componentId + "-main"}

		>
			<HeadMenu
				properties={{
					pageName: "header",
					trNamespace: "headMenu",
					image: props.base64Image,
					title: props.pageName,
					addedClass: "w-100 bg-primary-gradient",
					textClass: "txt-primary",
					menuButtonClass: "txt-primary m-2",
				}}
			/>
			{/* <PageHeader properties={props} /> */}

			<div
				className="content-container content-margin-top "
				key={componentId}
			>
				{setContent()}
				<CustomFooter></CustomFooter>
			</div >
		</div >
	);
};

Dashboard.propTypes = {
	pageName: PropTypes.string,
	trNamespace: PropTypes.string,
	icon: PropTypes.string,
	backgroundImage: PropTypes.string,
	translatePageTitle: PropTypes.bool,
	showHeaderTooltip: PropTypes.bool,
	base64Image: PropTypes.string
};


export default Dashboard;
